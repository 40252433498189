import axios from 'axios';

import { CacheManager } from 'utils/cache.manager';

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'x-portals-access-token': process.env.NEXT_PUBLIC_PORTALS_ACCESS_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  validateStatus: () => true,
});

export const cacheableApi = async <T>(cache: CacheManager, domain: string, getPath: string): Promise<T> => {
  const cacheKey = `domain:${domain}:backend-call:${getPath}`;

  if (await cache.has(cacheKey)) {
    const cachedResponse = <Promise<T>>await cache.get(cacheKey);

    if (cachedResponse) {
      return cachedResponse;
    } else {
      return getFromApiAndStoreInCache<T>(cache, getPath, cacheKey);
    }
  } else {
    return getFromApiAndStoreInCache<T>(cache, getPath, cacheKey);
  }
};

export const cacheableApiSurvey = async (cache: CacheManager, domain: string, getPath: string) => {
  const cacheKey = `${domain}${getPath}`;

  const cachedResponse: any = await axios
    .get(`${cacheKey}`, {
      headers: {
        'x-portals-access-token': process.env.NEXT_PUBLIC_PORTALS_ACCESS_TOKEN,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        return error.response;
      }

      return null;
    });

  if (cachedResponse.status === 200) {
    if (cachedResponse.data && cachedResponse.data.length !== 0) {
      return cachedResponse.data;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const sendResponcesSurveyApi = async (responces: any, participantId: string) => {
  const cacheKey = `${process.env.NEXT_PUBLIC_API_URL}/portal/survey/result`;

  const config = {
    headers: {
      'x-portals-access-token': process.env.NEXT_PUBLIC_PORTALS_ACCESS_TOKEN,
    },
  };

  const bodyParameters = {
    participantId: `${participantId}`,
    data: {
      elements: `${responces}`,
    },
  };

  const apiResponse = await axios
    .post(cacheKey, bodyParameters, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  if (apiResponse.status) {
    return apiResponse;
  } else {
    return 'Error in sending responce data.';
  }
};

export const sendSessionResponcesSurveyApi = async (responces: any, participantId: string) => {
  const cacheKey = `${process.env.NEXT_PUBLIC_API_URL}/portal/survey/session`;

  const config = {
    headers: {
      'x-portals-access-token': process.env.NEXT_PUBLIC_PORTALS_ACCESS_TOKEN,
    },
  };

  const bodyParameters = {
    participantId: `${participantId}`,
    surveyData: {
      pages: [
        {
          elements: `${responces}`,
        },
      ],
    },
  };

  const apiResponse = await axios
    .post(cacheKey, bodyParameters, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  if (apiResponse.status) {
    return apiResponse;
  } else {
    return 'Error in sending session responce data.';
  }
};

const getFromApiAndStoreInCache = async <T>(cache: CacheManager, getPath: string, cacheKey: string) => {
  const val = await api.get<T>(getPath).then(({ data }) => data);
  await cache.set(cacheKey, val);

  return val;
};

export const activityTrackApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ACTIVITY_TRACKER_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.NEXT_PUBLIC_ACTIVITY_TRACKER_TOKEN,
  },
});

export const stageClipUtilityApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_STAGECLIP_UTILITY_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.NEXT_PUBLIC_STAGECLIP_UTILITY_TOKEN,
  },
});

export const cacheableApiMerchandise = async (cache: CacheManager, domain: string, getPath: string) => {
  const cacheKey = `${domain}${getPath}`;

  const cachedResponse: any = await axios
    .get(`${cacheKey}`, {
      headers: {
        'x-portals-access-token': process.env.NEXT_PUBLIC_PORTALS_ACCESS_TOKEN,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        return error.response;
      }

      return null;
    });

  if (cachedResponse.status === 200) {
    if (cachedResponse.data && cachedResponse.data.length !== 0) {
      return cachedResponse.data.surveyData;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
